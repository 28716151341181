import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Shoulder Press 4-4-4-4\\@85% 1RM`}</p>
    <p>{`Pendlay Rows 4-4-4-4\\@85% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`15:00 AMRAP of:`}</p>
    <p>{`5-Ring Muscle Ups`}</p>
    <p>{`5-25ft shuttle run sprints (touching floor at each 25ft increment)`}</p>
    <p>{`15-KBS’s (53/35)`}</p>
    <p>{`5-25ft shuttle run sprints`}</p>
    <p><strong parentName="p">{`*`}{`We will have a special 3:00pm adaptive athlete class at the Ville
today so the class space will not be available for open gym members from
3:00-4:00pm.`}</strong></p>
    <p><strong parentName="p">{`*`}{`The 2017 CrossFit Games are live this week! We’ll be having a
members get together at the Barnett’s house Sunday, August 6th, from
12-6. We’ll eat, watch the Games, and swim if you’d like. This is a
family friendly event so bring the kids! Please bring a side dish (if
your last name starts with A-M) or dessert (if your last name starts
with N-Z). We’ll supply the meat and drinks. Email, call or text Daniel
to RSVP and for directions.  Sunday’s 12:30 class will be cancelled for
the party.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      